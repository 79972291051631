
.menu{
	font-family: 'FortunataCYR';
	position: absolute;
    width: 250px;
	top: 200px;
	left: calc(50% - 126px);
	background-color: rgb(212, 183, 30);
	border: 1px solid #c10000;
	box-shadow: 0 0 10px rgb(255, 0, 0);
	
	
	&__body{
		box-shadow: inset 0 0 20px 1px #ff3b3b;
		padding: 10px 20px 5px;
	}

	&__title{
		font-size: 32px;
		padding: 0 0 10px;
		text-align: center;
		color: #e80d0d;
		text-shadow: 0 0 10px black, 0 0 10px black;
	}

	&__button{
		font-family: 'AnotherCastle3';
		width: 100%;
		font-size: 25px;
		color: #000000;
		border: 2px outset #795548;
		background-color: rgba(158, 114, 0, 0.9);
		margin-bottom: 20px;
		padding: 3px 0 6px;
		box-shadow: 2px 2px 2px #0000009e;
		cursor: pointer;
		outline: none;
		line-height: 19px;

		&:hover, &--hover{
			color: #ff0404;
			text-shadow: 0 0 5px #ce0000;
		}
		&:active{
			background-color: rgba(51, 37, 0, 0.9);
			text-shadow: 0 0 5px red;
			border: 2px inset #795548;
			box-shadow: none;
		}
	}

	&__button-outside-open, &__button-outside-shop{
		position: absolute;
		border: 0;
		top: 0;
		left: 125px;
		background-image: url(../../../assets/img/buttons/buttonFon.png);
		background-color: black;
		background-size: cover;
		font-size: 25px;
		font-family: 'FortunataCYR';
		color: black;
		padding: 5px 10px 10px;
		cursor: pointer;
		outline: none;
		box-shadow: 0 0 7px 1px black;

		&:hover{
			color: red;
			text-shadow: 0 0 5px black;
		}
	}

	&__button-outside-shop{
		left: auto;
		right: 195px;
	}

	&__button-outside-new-wave{
		position: absolute;
		top: 8px;
		right: 25px;
		font-size: 22px;
		font-family: 'AnotherCastle3';
		color: black;
		padding: 5px 10px 10px;
		cursor: pointer;
		outline: none;
		box-shadow: 0 0 7px 1px black;
		background-color: #ba0707;
		border: 1px solid #610000;
		border-radius: 5px;
		line-height: 16px;

		&:hover{
			background-color: #eb0000;
		}
	}

	&__button-new-wave{
		background-color: #9d0000;

		&:hover, &--hover{
			background-color: #640000;
		}
	}

	&__button-sound-off{
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 35px;
		height: 35px;
		padding: 3px;
		cursor: pointer;
		outline: none;

		background-color: #d4b71e;
		border: 1px solid #540000;
		border-radius: 5px;

		& > img{
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
		}

		&:active{
			background-color: #6c5c0e;
		}

		&--off{
			background-color: #6c5c0e;

			&:hover{
				background-color: #806b07;
			}
		}

		&--on{
			box-shadow: inset 0 0 10px #d30000;

			&:hover{
				background-color: #f4cd0c;
			}
		}
	}

	&__close{
		position: absolute;
		top: -10px;
		right: -10px;
		height: 25px;
		width: 25px;
		background-color: #b40000;
		border-radius: 5px;
		box-shadow: 0 0 5px 0 black;
		cursor: pointer;
		
		&:hover{
			color: #dfdf00;
		}
		&:active{
			background-color: #bb5b00;
			color: black;
		}
	}
		
	&__close-body{
		box-shadow: inset 0 0 5px 0px black;
		height: 100%;
		border-radius: 5px;
		text-align: center;
		line-height: 18px;
		font-size: 22px;
		font-family: cursive;
	}

	&__footer{
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 0;
		padding: 5px 0;
	}

	&__footer-link-version{
		text-decoration: none;
		color: #ffa400;
	}

	&__footer-link-author{
		margin-left: 15px;
		text-decoration: none;
		color: #aa1000;
	}
}