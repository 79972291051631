.unit-buttons{
    position: absolute;
    opacity: 0;

    &:hover{
        opacity: 1;
    }

    &__wrapper{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-evenly;
    }

    &__button{ 
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: calc(33% - 10px);
        max-width: 50px;
        padding: 0 5px;
        background-color: rgb(212, 183, 30);
        border: 1px solid #c10000;
        box-shadow: 0 0 3px rgb(255, 0, 0), inset 0 0 15px 1px #ff3b3b;

        &:hover{
            background-color: rgb(212, 223, 30);
            cursor: pointer;
        }

        &--healing{
            &:hover{
                padding-top: 9px;
                padding-bottom: 1px;
    
                .unit-buttons__button-healing-coin{
                    display: block;
                }
            }

        }

        &-healing-coin{
            display: none;
            position: absolute;
            top: 0;
            left: 1px;
            font-size: 50%;
            width: 50%;
            text-wrap: nowrap;
            text-align: center;
            right: 1px;
            width: 100%;

            .unit-buttons__button--disabled:hover &{
                color: #f01313 !important;
            }
        }

        &-image{
            width: 100%;

            &--coin{
                width: 35%;
                margin-left: 1px;
            }
        }

        &--disabled{
            background-color: gray !important;
            border-color: rgb(77, 77, 77);
            box-shadow: 0 0 3px black;

            &:hover{
                cursor: not-allowed;
            }
        }
    }
}