* {margin: 0; padding: 0;}
html, body{ height: 100%;}
body{
	overflow:  hidden; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background-image: url(./assets/img/earthBlocks/blockEarth0.jpg);
	background-size: 40px;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Old versions of Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}

.nodrag {
	-webkit-user-drag: none;
	 -khtml-user-drag: none;
	   -moz-user-drag: none;
	     -o-user-drag: none;
		    user-drag: none;
}

#react{
	position: relative;
	overflow: auto;
    height: 100%;
}