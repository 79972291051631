@font-face {
    font-family: 'Calibri';
    src: url('./assets/fonts/Calibri.ttf');
}

@font-face {
    font-family: "FortunataCYR"; 
    src: url("./assets/fonts/FortunataCYR.ttf"); 
} 

@font-face {
    font-family: "AnotherCastle3"; 
    src: url("./assets/fonts/AnotherCastle3.ttf"); 
}

@font-face {
    font-family: "Cheri"; 
    src: url("./assets/fonts/Cheri.otf"); 
} 

